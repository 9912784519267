import { createRouter, createWebHistory } from "vue-router"

import MainIndex from "@/views/MainIndex";

import OrderCustomize from "@/views/OrderCustomize";


import OrderResult from "@/views/OrderResult";




const routes = [
    {
        path: "/",
        redirect: "/52haoma"
    },
    {
        path: "/proxy/:source",
        component:MainIndex,
        meta:{
            title: '手机靓号-可私人定制'
        }
    },
    {
        path: "/:source",
        component:MainIndex,
        meta:{
            title: '手机靓号-可私人定制'
        }
    },
    {
        path: "/customize/:source",
        component:OrderCustomize,
        meta:{
            title: '个性定制'
        }
    },
    {
        path: "/order-result/:orderId",
        component:OrderResult,
        meta:{
            title: '下单结果'
        }
    }


];

const router = createRouter({
    history: createWebHistory('/'),
    routes
});

export default router;